.border-table thead tr{
	background-color: #fff !important;
}

.border-table th:nth-child(1){

	/* Safari 3-4, iOS 1-3.2, Android 1.6- */
	-webkit-border-radius: 8px 0px 0px 0px; 

	/* Firefox 1-3.6 */
	-moz-border-radius: 8px 0px 0px 0px; 

	/* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
	border-radius: 8px 0px 0px 0px !important; 
}

.border-table th:nth-last-child(1){
	/* Safari 3-4, iOS 1-3.2, Android 1.6- */
	-webkit-border-radius: 0px 8px 0px 0px; 

	/* Firefox 1-3.6 */
	-moz-border-radius: 0px 8px 0px 0px; 

	/* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
	border-radius: 0px 8px 0px 0px;
}

.red{
    background: red !important;
}
._general_carousel_cointainer{
    position: relative;
    align-items: center;

    .carousel-btn{
        top: 45%;
        position: absolute;
        z-index: 1000;
    }

    .carousel-btn-prev{
        left: -2em;
    }

    .carousel-btn-next{
        right: -2em;
    }
}

@media (max-width: 1263px) {
    ._general_carousel_cointainer {
        margin: auto;
        max-width: 1000px;
        padding: 3em;
        .carousel-btn-prev{
            left: -0.5px !important;
        }
    
        .carousel-btn-next{
            right: -4px !important;
        }
    }
}


@media (max-width: 1005px) {
    ._general_carousel_cointainer {
        margin: auto;
        max-width: 700px;
        padding: 3em;
        .carousel-btn-prev{
            left: -0.5px !important;
        }
    
        .carousel-btn-next{
            right: -4px !important;
        }
    }
}


@media (max-width: 705px) {
    ._general_carousel_cointainer {
        margin: auto;
        max-width: 400px;
        padding: 3em;
        .carousel-btn-prev{
            left: -0.5px !important;
        }
    
        .carousel-btn-next{
            right: -4px !important;
        }
    }
}


@media (max-width: 425px) {
    ._general_carousel_cointainer {
        margin: auto;
        max-width: 350px;
        padding: 3em;
        .carousel-btn-prev{
            left: -0.5px !important;
        }
    
        .carousel-btn-next{
            right: -4px !important;
        }
    }
}

#customCarousel{

    width: 85%;
    margin: auto;
    align-items: center;
    position: relative;

    .btn-carousel-arrow{
        background: #FFC300;
        padding: 5px 10px;
        z-index: 1000;
        position: absolute;
    }

    .back-btn{
        left: 0px;
    }

    .next-btn{
        right: 0px;
    }
}

@media (max-width: 1237px) {
    #customCarousel{
        width: 90%;
    }
  }

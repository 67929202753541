
#event-explorer{
    max-height: 900px;
    overflow: auto;

    .no-event{
        width: 100%;
        max-width: 900px;
        margin: auto;
    }
}

.custom-not-found-data{
    background-color: #142334F5;
    margin: auto;
    padding: 50px;

    img{
        display: block;
        margin: 50px auto 20px auto;
        width: 100%;
        max-width: 300px;
    }

    .header-title-section{
        font-size: 50px;
        margin: 30px 0px;
    }
    @media (max-width: 962px) {
        
        .header-title-section{
            line-height: 1;
        }
    }

    button{
        
        font-weight: 700;
        font-size: 18px;
        width: 267px;
        height: 44.1px;
        left: 740px;
        top: 2584px;

        background: #FFC300;
    }
}

.event-home{
    .tab-event-active{

        background-color:#001D3D !important;
        border:2px solid #ffc300 !important;
        color: #FFC300 !important;
    }
    .tab-event-active::after{

        background-color:#001D3D !important;
        border:2px solid #ffc300 !important;
    }
    .event-tab{
        .title{
            width: 70%;
        }
        h3{
            font-family: 'UACadet';
            font-style: normal;
            font-weight: 400;
            /* font-size: 22px; */
            line-height: 24px;
        }

        .img-tab{
            justify-content: flex-end;
            width: 30%;
            display: flex;
            img{
                width: 30px;
            }
        }

        .notify{
            position:absolute;
            background-color: #7ED360;
            color: #253C1D;
            font-size: 12px;
            font-family: Segoe;
            padding: 3px 15px;
            border-radius: 4px;
            text-transform: uppercase;
            /* right: -30px; */
            top: -10px;
            z-index: 100;
        }
    }
}


#event-filter{
    color: #fff;
    text-transform: uppercase;
    margin: 15px 0px 15px 0px;

    span{
        padding: 5px 10px;
        font-family: Segoe;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 27px;
        letter-spacing: 1.5px;
        cursor: pointer;
    }

    .filter-form{
        color: #FFFFFF !important;
        input{
            font-family: Segoe;
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 21px;

            background-color: inherit;
            border-radius: 0px;
            border-bottom: 1px solid #fff;
            background-color: transparent;
        }

        input::placeholder {
            color: #FFFFFF !important;
            letter-spacing: 1.5px;
        }

        input:focus {
            outline: none;
        }
        
    }
    
    .filter-active{
        color: #FFC300;
        border-bottom: 1px solid #FFC300;
    }

    
}



@tailwind base;
/*Menu*/
.menu-top-bar {
  background-color: #ffc300;
  @apply text-black font-bold tracking-wide pl-10;
}
.menu-top-bar-days-indicator {
  transform: skew(-20deg);
  background-color: #061322;
  @apply text-white font-bold py-2 px-4 inline-block mx-3;
}
/* .menu-bar {
  background-color: #061322;
  @apply flex justify-between items-center;
} */
/* .menu-bar img {
  @apply w-24 p-2 lg:p-0 lg:ml-10;
} */
.menu-bar-ul {
  @apply z-10 w-full fixed lg:relative top-0 left-0 bg-black lg:bg-[#061322] h-screen lg:h-auto  lg:flex mr-3;
}
.menu-bar li {
  @apply lg:flex lg:items-center;
}
/* .menu-bar-item {
  @apply text-white px-5 py-4 lg:py-10 block uppercase transition-all duration-200 relative cursor-pointer;
} */
/* .menu-bar-item svg {
  @apply ml-2 text-lg text-[#ffc300];
} */
.menu-bar-item .language {
  @apply text-xl mr-1;
}
/* .menu-bar-item-active {
  @apply w-full bg-[#ffc300] text-[#061322] block font-bold px-10 py-5 uppercase transition-all duration-200;
} */
/* @media (min-width: 1024px) {
  .menu-bar-item-active {
    transform: skew(-20deg);
    background-color: #ffc300;
    color: #061322;
    @apply font-bold px-5 py-10 md:block uppercase  transition-all duration-200;
  }
} */
/* .menu-bar-item-login {
  @apply text-white uppercase font-bold py-5 px-10 block;
} */
/* @media (min-width: 1024px) {
  .menu-bar-item-login {
    transform: skew(-15deg);
    background-color: #ffc300;
    @apply uppercase font-bold py-2 px-4;
  }
} */
.menu-bar-item-search {
  color: #ffc300;
  @apply text-3xl ml-10;
}
.menu-bar-expand {
  @apply w-full lg:w-[150%] bg-black lg:bg-[#061322] lg:absolute lg:top-20 left-0 lg:left-[-50%] flex flex-col py-2 lg:p-5 z-20;
}
.menu-bar-expand li {
  @apply flex items-center lg:border-b-[.1px] border-[#ffc300];
  /* border-bottom: 0.01px solid #ffc300; */
}
.menu-bar-expand a {
  @apply block  w-full py-4 transition-all duration-200 hover:bg-[#ffc300] hover:text-[#061322];
}
.menu-bar-expand a svg {
  @apply mr-2;
}
.menu-bar-expand a:hover svg {
  color: #061322;
}
.menu-bar-expand li:last-child {
  @apply border-0;
}
/*Footer*/
.footer {
  background-color: #061322;
  @apply mt-10 w-full px-10 py-10 relative;
}
.footer-top {
  background-color: #061322;
  @apply absolute;
  width: 75%;
  height: 50px;
  top: -40px;
  left: 12.5%;
  border-radius: 200px 200px 0 0;
}
.text-yellow-c1 {
  color: #ffc300;
}
.bg-yellow-c1 {
  background-color: #ffc300;
}
.border-b-yellow-c4 {
  border-bottom: 4px solid #ffc300;
}
.border-t-yellow-c4 {
  border-top: 4px solid #ffc300;
}
.text-blue-c1 {
  color: #061322;
}
.bg-blue-c1 {
  background-color: #061322;
}
.bg-blue-c2 {
  background-color: #001d3d;
}
.transform-skew-20 {
  transform: skew(-20deg);
}
/*table */
.table {
	@apply w-full;
}
.table thead tr {
	@apply bg-gray-300 text-left;
}
.table th {
	@apply p-3
}
.table td {
	@apply border-b p-2
}
.table tbody tr {
	@apply hover:bg-gray-100
}
/* top buttons */
.top-buttons {
	@apply mb-10 flex justify-end w-full
}
.top-buttons a {
	@apply bg-gray-800 px-5 mx-2 active:scale-90 hover:bg-gray-600 text-white p-2 rounded
}
/*form admin */
.form {
	@apply relative shadow-lg p-10 mx-auto bg-gray-50
}

.form-event {
	@apply mx-auto p-10;
}
.form fieldset legend {
	@apply bg-gray-200 absolute rounded top-[-20px] left-10 text-gray-400 font-bold py-2 px-5 border border-gray-300
}
/*menu user */
.menu-user {

}
.menu-user a {
	@apply font-bold text-gray-500 block border-b border-gray-300 py-2 hover:bg-[#FFC30014] hover:pl-2 transition-all duration-75
}

.options {
  @apply hover:cursor-pointer hover:scale-105 active:scale-95;
}
.button-black {
  @apply bg-gray-900 hover:opacity-70 p-2 text-white rounded  active:scale-90 cursor-pointer;
}

.table-followers {
  @apply w-full text-xs;
}
.table-followers th {
  @apply border-b py-5 text-left;
}

.table-followers tbody td {
  @apply border-b py-3 align-middle
}

.table-followers tbody tr {
  @apply hover:bg-gray-100
}

@layer base {
  .no-tailwindcss-base h1,
    .no-tailwindcss-base h2,
    .no-tailwindcss-base h3,
    .no-tailwindcss-base h4,
    .no-tailwindcss-base h5,
    .no-tailwindcss-base h6 {
        font-size: revert;
        font-weight: revert;
    }

    .no-tailwindcss-base ol,
    .no-tailwindcss-base ul {
        list-style: revert;
        margin: revert;
        padding: revert;
    }
}

@tailwind components;
@tailwind utilities;

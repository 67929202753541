#how-to-play{
    position: relative;

    .header-title{
        z-index: 1000;
        .subtitle{
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 27px;
        }

        .title{
            
            font-family: 'UACadet';
            font-style: normal;
            font-weight: 400;
            font-size: 60px;
            line-height: 61px;

            color: #001D3D;
        }
    }

    .how-to-body{
        // text-align: center;
        // margin: auto;
    }
    @media (max-width: 756px) {
        .know-more{
            margin: auto !important;
            text-align: center;
        }

        .static-icons{
            display: none;
        }
        .static-final{
            display: none;
        }
      }
    .know-more{
        cursor: pointer;
        font-family: 'UACadet';
        color: #061322;
        font-size: 20px;
        line-height: 35px;
        max-width: 140px;
        margin-left: 25%;
    }


    .static-icons{
        position: relative;
        img{
            width: 72px;
        }

        .custom-position{
            position: absolute;
            left: 5rem;
            top: 5.8rem;
        }
    }

    .static-final{
        position: absolute;
        right: 0px;

        img{
            width: 100%;
            max-width: 300px;
        }
    }
}
.table {
    border: none;
    border-collapse: collapse;
    border-spacing: 0;
    font: normal 13px Arial, sans-serif;
}
.table thead th {
    background-color: #001D3D;
    border: none;
    color: #fff;
    padding: 10px;
    text-align: left;
}
.table tbody td {
    border: none;
    color: #000000;
    padding: 10px;
}
.table-rounded {
    border: none;
}
.table-rounded thead th {
    background-color: #001D3D;
    border: none;
    color: #fff;
}
.table-rounded thead th:first-child {
    border-radius: 10px 0px 0px 0px;
}
.table-rounded thead th:last-child {
    border-radius: 0 10px 0 0;
}
.table-rounded tbody td {
    border: none;
}
.table-rounded tbody tr:last-child td:first-child {
    border-radius: 0 0 0 10px;
}
.table-rounded tbody tr:last-child td:last-child {
    border-radius: 0 0 10px 0;
}
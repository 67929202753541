@font-face {
   font-family: AldotheApache;
   src: url(../fonts/AldotheApache.ttf);
}

@font-face {
   font-family: Segoe;
   src: url(../fonts//Segoe\ UI.ttf);
}
@font-face {
   font-family: SegoeBold;
   src: url(../fonts//Segoe\ UI\ Bold.ttf);
}

@font-face {
	font-family: UACadet;
	src: url(../fonts/UA\ Cadet.ttf);
}

/* .transform-section:hover{
	
	background-color:#001D3D !important;
	border:2px solid #ffc300 !important;
	color: #FFC300 !important;
} */



.alice-carousel__stage-item{
	margin: 0px 5px;
}

.transform-section::after:hover{
	
	background-color:#001D3D !important;
	border:2px solid #ffc300 !important;
	color: #FFC300 !important;
}

.transform-section{	
	padding: 0.7em 1.5em;
	transform: skew(-20deg);
	background-color: #ffc300;
	color: #061322;
	font-weight: 700;
	text-transform: uppercase;
  min-width: max-content; /*280px;  Original 328px */
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	cursor: pointer;
}
.transform-section::after{	
	width: 8px;
	height: 100%;
	background-color: #ffc300;
	content: '';
	color: '#fff';
	position: absolute;
	right: -15px;
}

.aldo {
	/* font-family: AldotheApache; */
	font-family: UACadet;
	letter-spacing: 2px;
}
.segoe {
	font-family: Segoe;
}
.segoe-bold-100 {
	font-family: SegoeBold;
}
.UA-Cadet{
	font-family: UACadet;
	letter-spacing: 2px;
}

.StripeElement {
	border: 1px solid #eee;
	padding: 30px;
	width: 500px;
 }
 
 .StripeElement--invalid {
	border: 1px solid red;
 }

 .ck {
	width: 100%;
 }

 .ck-editor__editable {
	min-height: 300px;
	width: 100%;
}

.profile-account  .ck-editor__editable {
	min-height: 150px;
	width: 100%;
}

.scrollbar{
	padding: 10px;
}

.custom-scroll::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: rgba(24, 24, 24, 0.5);
}

.custom-scroll::-webkit-scrollbar
{
	width: 8px;
	background-color: #C7C7C7;
	border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #fac037;
}

.neon-title {
  text-shadow:
    0 0 7px #fef9c3,
    0 0 10px #fef9c3,
    0 0 21px #fef9c3,
    0 0 42px #fef9c3,
    0 0 82px #fef9c3;
}
.basic-event-card{
    color: #fff;
    background: #001D3D;
    padding: 15px;
    border-top: 3px solid #FFC300;
    width: 100%;
    max-width: 360px;
}

.basic-event-card img{
    // margin: auto;
    // width: 100%;
    // border-radius: 5px;
}


.basic-event-card .title{
    font-family: 'UACadet';
    font-size: 20px;
    line-height: 1;
}

.basic-event-card .username{
    font-size: 11px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #FFC300;
}

.basic-event-card .rewards-container{
    display: flex;
    justify-content: flex-end;
}

.basic-event-card .basic-image{
    position: relative;
    padding: 10px 0px;
}

.basic-event-card .event-rewards{
    transform: skew(-20deg);
    background-color: #ffc300;
    color: #061322;
    padding: 0.4em 1em;
    font-weight: 700;
    text-transform: uppercase;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 200ms;
    width: max-content;
    padding: 0.1em 0.5em;
    font-size: 25px;
    height: max-content;
    font-family: 'UACadet';
}

.basic-event-card .header-basic-event{
    margin: 15px 0px;
}


.basic-event-card .event-date-container{
    background-color: #061322;
    padding: 10px 0px;
    text-align: center;
}

.basic-event-card .event-date:first-child {
    border-right: 1px solid #003566;
}

.basic-event-card .event-date{
    span{
        display: block;
    }

    .event-day{
        font-family: 'Segoe UI';
        font-style: normal;
        font-weight: 700;
        font-size: 21px;
        line-height: 28px;

        color: #FFC300;
    }

    .event-month{
        font-family: 'Segoe UI';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        /* identical to box height */


        color: #FFFFFF;
    }
}